import React, { useState } from "react";
import Layout from "../components/Layout/layout";
import useLanguage from "../hooks/useLanguage";

const TestPage = () => {
  const langToggle = useLanguage;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
    } else if (name === "email") {
      setEmail(value);
    } else {
      setCheckboxes({
        ...checkboxes,
        [name]: e.target.checked,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const errors = {};
    if (!name.trim()) {
      errors.name = "Name required";
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = " Invalid email format";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch("https://form.ar-design.com.ua/server", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, checkboxes }),
      });
      setIsSubmitted(true);

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Ошибка при отправке формы:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  console.log(isSubmitted);

  return (
    <>
      <Layout>
        <div className="contacts__map-info">
          {isSubmitted === false && (
            <h2 className="text-left pb-2">
              {langToggle(
                "Отримати консультацію дизайнера",
                "Получить консультацию дизайнера",
                "Get a designer consultation"
              )}
            </h2>
          )}
          {isSubmitted ? (
            <h2 className="text-center" style={{ color: "green" }}>
              {langToggle(
                "Форму відправлено успішно!",
                "Форма отправлена успешно!",
                "Form submitted successfully!"
              )}
            </h2>
          ) : (
            <div className={isSubmitted ? "d-none" : "d-block"}>
              <h2 className="text-left pb-2 pt-3 title">
                {langToggle(
                  "Хочу дизайн інтер'єру",
                  "Хочу дизайн интерьера",
                  "I want interior design"
                )}
              </h2>
              <ul>
                <li className="d-flex items-center">
                  <input
                    type="checkbox"
                    name="Квартира"
                    checked={checkboxes["Квартира"]}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  {langToggle("Квартира", "Квартира", "Apartment")}
                </li>
                <li className="d-flex items-center">
                  <input
                    type="checkbox"
                    name="Дом"
                    checked={checkboxes["Дом"]}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  {langToggle("Будинок", "Дом", "House")}
                </li>
              </ul>
              <h2 className="text-left pb-2 pt-3 title">
                {langToggle(
                  "Площа під дизайн проект",
                  "Площадь под дизайн проект",
                  "Area for design project"
                )}
              </h2>
              <ul>
                <li className="d-flex items-center">
                  <input
                    type="checkbox"
                    name="от 36 до 58 мкв"
                    checked={checkboxes["от 36 до 58 мкв"]}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  {langToggle(
                    "від 36 до 58 кв.м",
                    "от 36 до 58 мкв",
                    "from 36 to 58 sq.m"
                  )}
                </li>
                <li className="d-flex items-center">
                  <input
                    type="checkbox"
                    name="от 66 до 85 мкв"
                    checked={checkboxes["от 66 до 85 мкв"]}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  {langToggle(
                    "від 66 до 85 кв.м",
                    "от 66 до 85 мкв",
                    "from 66 to 85 sq.m"
                  )}
                </li>
                <li className="d-flex items-center">
                  <input
                    type="checkbox"
                    name="от 100 до 165 мкв"
                    checked={checkboxes["от 100 до 165 мкв"]}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  {langToggle(
                    "від 165 до 165 кв.м",
                    "от 100 до 165 мкв",
                    "from 165 to 165 sq.m"
                  )}
                </li>

                <li className="d-flex items-center">
                  <input
                    type="checkbox"
                    name="больше 165 мкв"
                    checked={checkboxes["больше 165 мкв"]}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  {langToggle(
                    "більше 165 мкв",
                    "больше 165 мкв",
                    "more than 165 sq.m"
                  )}
                </li>
              </ul>
              <h2 className="text-left pb-2 py-3 title">
                {langToggle(
                  "Хочу консультацію дизайнера інтер'єру",
                  "Хочу консультацию дизайнера интерьера",
                  "I would like to consult an interior designer"
                )}
              </h2>

              <ul>
                <li className="d-flex items-center pb-4">
                  <input
                    type="text"
                    placeholder={langToggle("Ім'я", "Имя", "Name")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="input"
                  />
                </li>
                {errors.name && (
                  <p style={{ color: "red" }} className="text-left pb-2">
                    {errors.name}
                  </p>
                )}
                <li className="d-flex items-center pb-2">
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="input"
                  />
                </li>
                {errors.name && (
                  <p style={{ color: "red" }} className="text-left">
                    {errors.email}
                  </p>
                )}
              </ul>

              <button
                className="d-flex button-link button-submit"
                onClick={handleSubmit}
              >
                <span className="button-link--wrapper">
                  <span className="link-title">
                    {isSubmitting
                      ? `${langToggle("Вирушає", "Отправляется", "Sent")}...`
                      : `${langToggle("Відправити", "Отправить", "Send")}`}
                  </span>
                 
                </span>
              </button>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};

export default TestPage;
